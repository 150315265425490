
import Moderator from '@/models/bread/Moderator/chapterStep'


export default {
  key:'ChapterStep',
  name:{
    singular:'Step',
    plural:'Steps',
  },
  parents:[
    'Chapter',
  ],
  grandParents:[
    'Book',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}