<template>
  <v-select
    :label="label"
    :key="key"
    class="white"
    :items="data"
    item-text="name"
    :item-value="itemValue"
    v-model="inputVal"
    :loading="api.isLoading"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["value","model","key","label","itemValue",],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    code: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      if(!this.value){
        this.value = resp[0][this.itemValue];
      }
      this.$emit("input", this.value);
    };
    //EOC
  },
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    fetch(model) {
      this.api.url =`${this.$api.servers.game}/api/v1/en/moderator/data/${model}`
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch('page');
  },
};
</script>
