<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <PageEdit
    :role="role"
    :model="model"
    :url="`${this.$api.servers.game}/api/v1/en/moderator/book/${this.$route.params.grandParentId}/chapter/${this.$route.params.parentId}/step/edit`"
    action="edit"
    :self="me"
    :callbackSuccess="callbackSuccess"
  ></PageEdit>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import modelGrandParent from '@/models/items/book'
  import modelParent from '@/models/items/chapter'
  import model from '@/models/items/chapterStep'
  //EOC
  import PageEdit from "@/components/Moderator/ChapterStep/PageEdit";
  import { mapState } from 'vuex'
  export default {
    components:{
      PageEdit,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
    'grandParent',
      'parent',
      'self',
    ],
    data: () => ({
      //BOC:[role]
      role:'Moderator',
      //EOC
      //BOC:[model]
      modelGrandParent: modelGrandParent,
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[parent]
      myParent:{
        id:null,
      },
      //EOC
      //BOC:[self]
      me:{
        id:null,
      },
      //EOC
       //BOC:[grand parent]
       myGrandParent:{
        id:null,
      },
      //EOC
    }),
    created() {
      //BOC:[guard]
      if(!this.model[this.role].edit) {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id,this.self))
        return
      }
      //EOC
      //BOC:[parent]
      if(this.parent) this.myParent = this.$_.cloneDeep(this.parent)
      //EOC
      //BOC:[self]
      if(this.self) {
        this.me = this.$_.cloneDeep(this.self)
      }
      else {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id))
      }
      //EOC
        //BOC:[grandparent]
        if(this.grandParent){
        this.myGrandParent = this.$_.cloneDeep(this.grandParent)
      } else{
        this.myGrandParent.id = this.$route.params.grandParentId
      }
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelGrandParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelGrandParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.myGrandParent && this.myGrandParent.name) ? this.myGrandParent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.myGrandParent),
        exact:true,
      })
      //
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:{ name: "PageModeratorBookRead",params:{parentId: this.$route.params.grandParentId} },
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.myParent && this.myParent.name) ? this.myParent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:{ name: "PageModeratorBookChapterRead",params:{parentId: this.$route.params.grandParentId,id:this.$route.params.parentId} },
        exact:true,
      })
      //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Page'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:path,
        exact:true,
      })

       this.breadcrumbs.push({
        text:this.$t("action.edit"),
        to:this.$_getRouteGrandChildEdit(this.role,this.model.key,this.$route.params.id,this.self,this.modelParent.key,this.$route.params.parentId,this.myParent,this.modelGrandParent.key,this.$route.params.grandParentId,this.myGrandParent),
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess(resp) {
        let redirect = this.$_getRouteGrandChildRead(this.role,this.model.key,this.$route.params.id,resp,this.modelParent.key,this.$route.params.parentId,this.myParent,this.modelGrandParent.key,this.$route.params.grandParentId,this.myGrandParent)
        redirect.query = {tab:this.model.key}
        this.$router.push(redirect)
      },
    }
  }
</script>